
import { LaunchRequest, TheiaCloud, RequestOptions } from '@eclipse-theiacloud/common';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SessionLauncher',
  props: {
    serviceUrl: String,
    appDefinition: String,
    email: String,
    appId: String,
    useEphemeralStorage: Boolean,
    workspaceName: String,
    token: String
  },
  created() {
    if (this.email) {
      this.startSession(10);
    }
  },
  data() {
    return {
      text: 'Please wait while we get your Theia session ready...',
      showSpinner: true
    };
  },
  watch: {
    email() {
      if (this.email) {
        this.startSession(10);
      }
    }
  },
  methods: {
    startSession(retries: number) {
      console.log('Calling to ' + this.serviceUrl);
      const launchRequest = this.useEphemeralStorage
        ? LaunchRequest.ephemeral(this.serviceUrl!, this.appId!, this.appDefinition!, undefined, this.email)
        : LaunchRequest.createWorkspace(
            this.serviceUrl!,
            this.appId!,
            this.appDefinition!,
            undefined,
            this.email,
            this.workspaceName
          );
      const options: RequestOptions = {
        accessToken: this.token,
        retries,
        timeout: 300000
      };
      TheiaCloud.launchAndRedirect(launchRequest, options).catch(error => {
        this.text = error;
        this.showSpinner = false;
        console.error(error);
      });
    }
  }
});
